import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import { BREAKPOINT } from '@latitude/core/utils/constants';
import Section from '@latitude/section';
import SkiArticle from './articles/20-best-ski-resorts-in-the-world.js';
import { graphql } from 'gatsby';
import Hero from '../../components/Hero/Hero';
import { AnalyticsContext } from 'latitude-analytics';
import Layout from '@/components/layout';
function TravelInspirationArticleTemplate({
  data, // this prop will be injected by the GraphQL query below.
  location
}) {
  const { markdownRemark } = data; // data.markdownRemark holds our post data
  const { frontmatter, html } = markdownRemark;
  const site = {
    url: 'https://www.28degreescard.com.au',
    title: 'Latitude 28&deg; Global'
  };
  const heroPosition = `${frontmatter.heroPosition || ''}`;

  const thumbnailUrl = require(`../../pages/credit-cards/28-degrees/images/travel-inspiration/thumbnails/${frontmatter.id}.jpg`);

  const socialTitle = `${frontmatter.title} | ${site.title}`;
  const canonicalUrl = `${site.url}${frontmatter.path}`;
  const [analytics] = useContext(AnalyticsContext);

  // adding pageData to analytics
  analytics.pageData = {
    page_pageTitle: `${frontmatter.title}`,
    page_pageType: 'blog-page',
    page_siteSection: 'blog',
    page_siteSubsection: 'travel-inspiration'
  };

  return (
    <Layout location={location}>
      <main>
        <Helmet
          title={`${frontmatter.title} | Travel Inspiration | ${site.title}`}
        >
          <link rel="canonical" href={canonicalUrl} />

          <meta property="og:type" content="article" />
          <meta property="og:title" content={socialTitle} />
          <meta property="og:description" content={frontmatter.description} />
          <meta property="og:url" content={canonicalUrl} />
          <meta property="og:site_name" content={site.title} />

          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={socialTitle} />
          <meta name="twitter:description" content={frontmatter.description} />
          <meta name="twitter:image" content={`${site.url}${thumbnailUrl}`} />
        </Helmet>

        <Hero
          h1={frontmatter.title}
          intro={frontmatter.subTitle}
          darkBg
          bg={
            require(`../../../static/travel-inspiration/images/${frontmatter.heroBackgroundImage}`)
              .default
          }
          css={`
            && {
              text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.2);
              @media (min-width: ${BREAKPOINT.LG}) {
                padding: 80px 0;
              }
            }
          `}
        />

        <nav aria-label="breadcrumb">
          <ol className="breadcrumb sr-only">
            <li className="breadcrumb-item">
              <a href="/">Home</a>
            </li>
            <li className="breadcrumb-item">
              <a href="/travel-inspiration">Travel Inspiration</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {frontmatter.title}
            </li>
          </ol>
        </nav>

        <Section
          className=""
          id="main"
          css={`
            && {
              padding: 48px 0;
              @media (min-width: ${BREAKPOINT.LG}) {
                padding: 72px 0;
              }
            }
            h2 {
              font-size: 28px;
            }
          `}
        >
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              {frontmatter.title !== '20 Best Ski Resorts In The World' ? (
                <div dangerouslySetInnerHTML={{ __html: html }} />
              ) : (
                <SkiArticle />
              )}
            </div>
          </div>
        </Section>
      </main>
    </Layout>
  );
}

export default TravelInspirationArticleTemplate;

export const pageQuery = graphql`
  query ArticleById($id: String!) {
    markdownRemark(frontmatter: { id: { eq: $id } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        heroBackgroundImage
        id
        title
        description
        travelTags
      }
    }
  }
`;
